.App {
  min-height: 100vh;
  background: url("./assets/background-no-stars.png") no-repeat center center
    fixed;
  background-color: #d5d7d8;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-family: "Roboto";
  color: rgba(0, 0, 0, 0.87);
}

@media (max-height: 900px) {
  .App-Container {
    max-height: calc(100vh - 150px);
  }
}

@media (max-width: 600px), (max-height: 650px) {
  body {
    background-image: url("./assets/rocket_image.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .App {
    background-image: none;
    background-color: rgba(255, 255, 255, 0.7);
    /* background-color: rgba(255, 255, 255, 0.5); */
  }
}

.App-Header {
  position: relative;
  width: 100vw;
  max-width: 1200px;
  padding: 24px;
}

.App-Header h1 {
  text-align: center;
  margin: 0;
}

.mvsi-logo {
  width: 125px;
  height: auto;
  position: absolute;
  left: 0;
}

.overlay-contents {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  padding: 24px;
  width: 100%;
  height: 100%;
}

@media (max-width: 600px), (max-height: 650px) {
  .App-Header {
    text-align: center;
    padding: 16px;
    margin: 0;
  }
  .mvsi-logo {
    position: relative;
    padding-bottom: 16px;
    left: unset;
  }

  .overlay-contents {
    padding: 0;
  }
}

.background-image-clipper {
  /* position: relative; */
  width: 900px;
  height: 500px;
  overflow: hidden;
}

.background-image-container {
  position: absolute;
  width: 100vw;
  min-width: 100vw;
  height: 100vh;
  min-height: 100vh;
  background-image: url("./assets/rocket_image.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: 10;
  left: calc(-50vw + 450px);
  top: calc(-50vh + 375px);
}

.App-Container {
  position: relative;
  width: 1200px;
  height: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 19px 38px rgb(0 0 0 / 13%), 0 15px 12px rgb(0 0 0 / 13%),
    0 30px 80px 20px rgb(0 0 0 / 31%);
  overflow: hidden;
  background-image: url("./assets/rocket_image.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.welcome-header {
  font-weight: 500;
  font-size: 30px;
  color: rgba(0, 0, 0, 0.87);
}

@media (max-width: 600px), (max-height: 650px) {
  .App-Container {
    background: none;
    box-shadow: none;
  }
  .App-Header {
    margin-top: 15px;
  }
  .welcome-header {
    margin: 0;
    font-size: 24px;
  }
  .help-desk {
    display: none;
  }
}

@media (max-width: 1200px), (max-height) {
  .App-Container {
    width: 100vw;
    height: calc(100vh - 164px);
  }
}

.login-existing {
  text-align: center;
  margin: 0;
  font-size: 20px !important;
  font-weight: 400 !important;
  margin-bottom: 82px;
}

@media (max-width: 1200px), (max-height: 650px) {
  .login-existing {
    margin-bottom: 20px !important;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  cursor: pointer;
}

.App-Container h4 {
  font-weight: 500;
}

.onboard-ts {
  height: 132px;
  max-height: 132px;
}

.onboard-ts a {
  width: 200px;
  height: 132px;
  max-width: 200px;
  display: inline-block;
}

.logo-block {
  display: inline-block;
  width: 50%;
  text-align: center;
}

@media (max-width: 600px), (max-height: 650px) {
  .logo-block {
    width: 100%;
    flex-basis: 100%;
    margin-bottom: 24px;
  }
}

.help-desk {
  color: white;
  font-size: 2.3rem;
  font-weight: 500;
  margin: 0;
  position: absolute;
  bottom: 60px;
  left: 0;
  width: 100%;
  text-align: center;
}

.ts-logo {
  padding: 12px;
  width: 200px;
  height: 132px;
  transition: border linear 100ms;
  float: right;
}

.ts-logo img {
  height: 100px;
}

.ts-logo:hover {
  border-color: rgb(47, 90, 184);
}

.onboard-logo {
  padding: 12px;
  display: flex;
  width: 200px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 132px;
  transition: border linear 100ms;
  float: left;
}

.onboard-logo:hover {
  border-color: rgb(47, 90, 184);
}

.onboard-logo img {
  height: 80px;
}

.onboard-logo h1 {
  margin: 0;
  font-weight: 400;
}

.onboard-logo,
.ts-logo {
  border: 2px solid #b8b8b8;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 25px;
  background-color: white;
}
